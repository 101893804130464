<template>
    <div id="app">
       404
    </div>
</template>

<script>

export default {
  name: 'NotFoundLayout',
  data () {
    return {
      success: '',
    }
  }

}

</script>
